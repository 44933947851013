.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;

		.fa-star {
			background-image: url(RESOURCE/img/fullstar-1.svg);
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-size: 18px;

			&:before {
				display: none;
			}
		}

		.fa-star-o {
			background-image: url(RESOURCE/img/borderstar-4.svg);
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-size: 18px;

			&:before {
				display: none;
			}
		}
	}
}