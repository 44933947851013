.regionen-v1 {
	margin-top: var(--page-margin-top);

	.searchbar-v1 {
		position: unset;
		margin-bottom: 50px;

		.container {
			max-width: 100%;
			width: 100%;
			padding: 0;
		}

		.search-form-area {
			border-right: 1px solid #f4f4f4;
			border-left: 1px solid #f4f4f4;
		}
	}

}