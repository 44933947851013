.search-unit-v2 {

	@media (min-width:993px) {
		background-color: var(--color-white);
	}

	position: relative;

	@media (min-width:993px) {
		margin-left: 15px;
		padding: 0;
		box-shadow: 0 0 5px rgba(0, 0, 0, .18);
		border-radius: 5px;
		margin-bottom: 35px;
		border: 2px solid #d6d6d6;
	}

	@media (max-width:992px) {
		margin: 0 auto 20px;
		max-width: 440px;
	}

	&:hover {
		border-color: var(--color-secondary);
	}

	.map-marker {
		display: inline-block;
		width: 30px;
		height: 50px;
		text-align: center;

		.fa-map-marker-alt {
			position: relative;
			top: 12px;
			cursor: pointer;
			font-size: 24px;
			color: var(--color-primary);
		}

		.fa-map-marker-alt:hover,
		.fa-map-marker-alt.selected {
			color: #565a5c;
		}
	}

	.img-col {
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		max-height: 270px;

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 50px;
					height: auto;
					bottom: 0;
					top: 0;
					position: absolute;
					border-radius: 0;
					font-size: 0;
					cursor: pointer;
					transition: all 0.25s ease 0s;
					transform: none;
					color: var(--color-white);
					font-size: 46px;
					line-height: 8px;
					text-align: center;
				}

				.owl-prev {
					background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
					background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
					background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1);
					left: 0;
				}

				.owl-next {
					right: 0;
					background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
					background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
					background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1);
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		@media (min-width:993px) {
			left: -16px;
			top: -15px;
		}

		.img-content {

			.full-img {
				width: 100%;
				height: auto;

			}


			.fav-icon {
				position: absolute;
				cursor: pointer;
				right: 7px;
				top: 5px;
				color: #fff;
				margin: 3px;
				font-size: 18px;
				width: 39px;
				text-align: center;
				height: 33px;

				line-height: 31px;
				z-index: 1;

				@media (min-width:993px) {
					background-color: rgba(0, 0, 0, .3);
					border-radius: 5px;
				}
			}


		}


	}

	.info-col {
		position: relative;
		padding: 25px 15px;

		.unit-title {
			margin-bottom: 0px;
			padding-top: 10px;

			@media (max-width:992px) {
				padding-top: 20px;
			}


		}

		.show-mob {
			.unit-distance {
				background-color: var(--color-white);
				border-radius: 15px;
				position: absolute;
				top: -55px;

				@media (max-width:992px) {
					top: -36px;
				}
			}
		}

		.unit-distance {
			padding: 0 15px;
			font-weight: bold;
			right: 0;
			top: 0;
			font-size: 12px;
			line-height: 30px;
			color: rgba(0, 0, 0, .7);
			background-color: #e6f2f7;
			border-radius: 15px;
		}


		@media (max-width:992px) {
			margin: 0 10px;
			padding: 15px;
			position: relative;
			background-color: #fff;
			background-color: var(--color-white);
			top: -35px;
			border-radius: 5px;
			z-index: 5;
			box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		}



		.region-bl {
			display: inline-flex;
			align-items: center;
			position: absolute;
			top: 0px;
			right: 10px;

			span {
				color: var(--color-grey-dark);
				font-size: 13px;
			}
		}

		.text-truncate {
			color: var(--color-secondary);
			font-size: 13px;
			margin-bottom: 20px;
		}

		.flex-align-center {
			padding-bottom: 14px;
			border-bottom: 1px solid var(--color-grey-normal);
			margin-bottom: 14px;
		}

		.search-result-properties {
			margin: 0 !Important;

			li {
				display: inline-block;
				list-style: none;
				text-align: center;
				float: left;
				padding: 0 0 0 10px;

				&:first-child {
					padding-left: 0;
				}

				@media (max-width:360px) {
					padding-left: 5px;
				}

				i {
					font-size: 16px;
					color: var(--color-grey-dark);
					font-style: normal;
					width: 28px;
					height: 28px;
					background-color: var(--color-grey-normal);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					@media (max-width:360px) {
						font-size: 14px;
						width: 26px;
						height: 26px;
					}

					&.cross {
						&:after {
							width: 1.5px;
							height: 100%;
							position: absolute;
							content: "";
							background-color: var(--color-grey-dark);
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
					}
				}
			}
		}

		.unit-price-rat {
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			-webkit-box-pack: justify;
			justify-content: space-between;
			-webkit-box-align: center;
			align-items: center;

			.prices {
				position: relative;
				z-index: 2;
				min-width: 160px;
			}


			.unit-link {
				text-transform: uppercase;
				color: var(--color-primary);
				font-size: 13px;
				font-weight: 700;
				position: relative;
				top: 2px;
			}
		}

		.alternatives {

			font-size: 16px;
			margin-bottom: 15px;

			.altheading {
				color: var(--color-red);
				margin-bottom: 10px;
				display: block;
				font-weight: 600;
			}

			.li-bullet {

				li {
					list-style: none;
					background-color: var(--color-grey-light);
					padding: 10px 15px;
					color: var(--color-grey-dark);
					border: 1px solid var(--color-grey-normal);
					border-bottom: none;

					&:first-child {
						border-radius: 5px 5px 0 0;
					}

					&:last-child {
						border-radius: 0 0 5px 5px;
						border-bottom: 1px solid var(--color-grey-normal);
					}

					a {
						color: var(--color-black);
						text-decoration: underline;

						&:hover {
							color: var(--color-red);
							text-decoration: none;
						}
					}
				}

				.fa {
					color: var(--color-red);
					font-size: 12px;
					line-height: 16px;
				}
			}

			.invalid {
				text-decoration: line-through;
			}

			.special {
				color: var(--color-red);
				font-weight: bold;
			}
		}

		.rentRate {
			p {
				margin: 0;

				strong {
					font-size: 21px;
					font-weight: 600;
					color: var(--color-black);

					@media (max-width:992px) {
						font-size: 18px;
					}
				}

				.price-bl {
					display: inline-block;

					i.fa {
						color: var(--color-grey-dark);
						font-size: 12px;
						margin-left: 5px;
					}

					.price-start {
						display: inline-block;
					}
				}

				span {
					font-size: 12px;
					color: var(--color-grey-dark);
					display: block;
					line-height: 18px;
				}
			}

			a {
				color: var(--color-black);
			}

			.price-info {

				i {
					color: var(--color-grey-dark);
					font-size: 12px;
					margin-left: 5px;
				}
			}
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-red);
			color: var(--color-white);
			padding: 10px 15px;
			border: 3px dashed var(--color-white);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;
			font-weight: bold;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding: 0;
			list-style: none;
			margin: 0;
			margin-top: 10px;

			li {
				margin: 0 0 10px;
				font-size: var(--font-size-md);
			}
		}
	}

	@media (min-width:993px) {
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;


		.info-col {
			width: 60%;
			position: relative;
			padding: 25px 15px;

			.price-anchor {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 80%;
			}
		}

		.img-col {
			@mixin flex;
			position: relative;

			.img-content {
				position: absolute;
				@mixin size100;

				.item {
					height: 270px;
				}


				.full-img {
					height: 100%;
					object-fit: cover;

				}
			}

		}

	}

	.unit-place {
		font-size: 16px;


		li {
			display: inline-block;
			position: relative;
			padding-left: 10px;
			margin-left: 5px;
			color: var(--color-black);

			@media (max-width:360px) {
				font-size: 14px;
				padding-left: 3px;
			}

			&:first-child {
				padding-left: 0;
				margin-left: 0;

			}

			i {
				font-size: 18px;
				opacity: .2;
				margin-right: 3px;

				@media (max-width:360px) {
					font-size: 15px;
					margin-right: 2px;
				}
			}
		}
	}

	.ratings {
		z-index: 1;
		margin: 0;
		position: absolute;
		top: 7px;
		background-color: rgb(255 255 255 / 70%);
		border-radius: 5px;
		left: 10px;
		color: #fff;
		padding: 10px;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		font-size: 16px;

		i {
			margin: 0 1px;
		}

		.fa-star {
			background-image: url(RESOURCE/img/fullstar-1.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}

		.fa.fa-star-o {
			background-image: url(RESOURCE/img/borderstar-4.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}

	}

	.unit-properties {
		padding-bottom: 10px;
		border-bottom: 1px solid var(--color-grey-normal);
		margin-bottom: 10px;
	}

	.unit-icons {
		display: flex;
		align-items: center;
		margin-top: -10px;

	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;

		.reducedprice {
			color: var(--color-red);
		}
	}


}