.searchpanel-v2 {
	position: relative;
	text-align: center;
	padding-top: 10px;


	.searchbar {
		display: inline-block;
		border-radius: 30px;
		left: 0px;
		background-color: #fff;
		background-color: var(--color-white);

		@media (min-width:993px) {
			left: 0px;
			position: absolute;
		}

		@media (min-width:1200px) {
			padding-left: 15px;

		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}
	}

	.search-form-area {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
			background: var(--color-white);
			top: 0;
			left: 0;
			width: 100%;
			position: absolute;
			z-index: 50;
			box-shadow: 0 0 15px rgb(0 0 0 / 20%);
			padding: 15px 10px;
			border-radius: 5px;
		}

		.form-control {
			background-color: var(--color-white);
			border-radius: 0;
			width: 100%;
			height: inherit;
			box-shadow: inherit;
			border: none;
			font-weight: 600;
			padding-right: 0;
			font-size: 15px;
			text-align: left;


			@media (max-width:992px) {
				height: auto;
				padding-left: 40px;
				top: 5px;
			}

		}
	}


	.form-flex {
		padding: 0 8px;

		@media (max-width:992px) {
			width: 100%;
		}

		.form-bx-area {
			padding-right: 20px;
			position: relative;
			width: 100%;

			@media (min-width:1200px) {
				padding-right: 50px;

			}

			@media (max-width:992px) {
				padding-right: 0;
				border-bottom: 2px solid #e5e5e5;
				border-bottom: 2px solid var(--color-grey-normal);
				padding-bottom: 3px;
				margin-bottom: 15px;
			}


			.fa {
				position: absolute;
				top: 8px;
				color: var(--color-grey-dark);
				z-index: 2;
				font-size: 20px;
				pointer-events: none;

				@media (max-width:992px) {
					left: 0px;
				}

				@media (min-width:993px) {
					right: -6px;
				}

			}
		}
	}



	.form-btn {

		.btn {
			-webkit-box-align: center;
			align-items: center;
			right: -15px;
			display: -webkit-box;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			font-size: 18px;
			border-radius: 30px;
			font-weight: 700;
			height: 60px;
			line-height: 1;
			position: relative;

			i {
				margin-left: 8px;
				color: rgba(0, 0, 0, .25);
				font-size: 20px;
				margin-right: 12px;

				@media (max-width:992px) {
					font-size: 16px;
					margin-right: 6px;
				}
			}

			@media (max-width:992px) {
				font-size: 13px;
				position: relative;
				height: 35px;
				padding-right: 15px;
				right: 0;
			}
		}
	}

	.traveltime {
		@media (min-width:993px) {
			min-width: 170px;
		}
	}

	.guestbox {
		@media (min-width:993px) {
			max-width: 140px;
		}
	}

	.filter-btn {
		display: inline-block;
		padding-left: 20px;
		color: #fff;
		font-size: var(--font-size-md);

		.btn-filter {
			padding-right: 0px;
			margin-right: 3px;
			border: none;
			background-color: transparent;
		}

		@media (min-width: 993px) {
			position: absolute;
			top: 20px;
			right: -170px;


			a {
				color: var(--color-white);
				text-decoration: none;

				&hover {
					color: var(--color-white);
					text-decoration: none;
				}
			}

			.btn-filter {
				@media (min-width:993px) {

					/*border-right: 1px solid var(--color-white);*/
					top: 0;
					color: var(--color-white);

					&:hover {
						color: var(--color-white);
					}

				}
			}
		}

		.spacer {
			color: var(--color-white);

			@media (max-width: 992px) {
				color: var(--color-black);
			}
		}

		@media (max-width: 992px) {
			position: absolute;
			right: 20px;
			bottom: 20px;
			display: inline-block;
			padding-left: 20px;
			font-size: var(--font-size-md);
			font-weight: 400;

			a {
				color: var(--color-black);
				text-decoration: none;

				&:hover {
					color: var(--color-primary);
					text-decoration: none;
				}
			}

			.btn-filter {
				@media (max-width:992px) {
					/*border-right: 1px solid var(--color-black); */
					top: 0;
					color: var(--color-black);

					&:hover {
						color: var(--color-primary);

					}

				}

			}

		}

	}


	.hidden {
		display: none !important;
	}
}