.static-view {
	.inner-banner {

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		height: 78vh;
		margin-bottom: 30px;

		&.region-8799 {
			background-image: url(RESOURCE/img/banner-erkenbollingen.jpg);
		}

		&.region-8797 {
			background-image: url(RESOURCE/img/banner-Halblech.jpg);
		}

		&.region-8798 {
			background-image: url(RESOURCE/img/banner-Hohenschwangau.jpg);
		}

		&.region-8800 {
			background-image: url(RESOURCE/img/banner-hopferau01.jpg);
		}

		&.region-10600 {
			background-image: url(RESOURCE/img/banner-lechbruck.jpg);
		}

		&.region-10988 {
			background-image: url(RESOURCE/img/banner-kluftern.jpg);
		}

		&.region-8766 {
			background-image: url(RESOURCE/img/banner-Ostallgaeu01.jpg);
		}

		&.region-8795 {
			background-image: url(RESOURCE/img/banner-forggensee01.jpg);
		}

		&.region-9213 {
			background-image: url(RESOURCE/img/banner-Weissensee.jpg);
		}

		&.region-8796 {
			background-image: url(RESOURCE/img/banner-rosshaupten01.jpg);
		}
	}
}

.empfehlenswerte-v1 {
	.region-8784 {
		background-image: url(RESOURCE/img/fussen-img.jpg);
	}

	.region-8792 {
		background-image: url(RESOURCE/img/pfronten-img.png);
	}

	.region-8794 {
		background-image: url(RESOURCE/img/hopfenamSee-img.png);
	}

	.region-8793 {
		background-image: url(RESOURCE/img/schwangau-img.jpg);
	}

	.region-8800 {
		background-image: url(RESOURCE/img/banner-hopferau01.jpg);
	}

	.region-8795 {
		background-image: url(RESOURCE/img/rieden-img.jpg);
	}

	.region-8800 {
		background-image: url(RESOURCE/img/banner-hopferau01.jpg);
	}

	.region-img-col {
		height: 300px;
		background-size: cover;
	}

	.region-title {
		background: rgb(204 204 204 / 80%);
		position: absolute;
		bottom: 10px;
		width: calc(100% - 50px);
		left: 25px;
		border-radius: 4px;
		color: #fff;
		padding: 4px 15px;
	}
}



.faq-search-v1 {
	width: 100%;

	h2 {
		margin-bottom: 30px;
	}

	.card {
		border: 0;

		.card-header {
			padding: 0;
			margin-bottom: 0;
			background-color: #fff;
			border-bottom: 0;
			border-radius: 0 !important;
			border: 0;

			.btn {

				width: 100%;
				text-align: left;
				padding: 0;
				border: 0;
				margin: 0;
				color: #000;
				padding-right: 35px !important;
				border-radius: 0 !important;

				&:hover {
					text-decoration: none;
				}

				&:before {
					content: "";
					height: 4px;
					width: 25px;
					background: #878787;
					position: absolute;
					right: 20px;
					top: 22px;

					@media (max-width:767px) {
						height: 2px !important;
						width: 20px !important;
						top: 20px !important;
					}
				}

				&:after {
					content: "";
					height: 26px !important;
					width: 4px !important;
					background: #878787;
					position: absolute;
					right: 30px !important;
					top: 11px !important;
					background-image: none !important;

					@media (max-width:767px) {
						height: 20px !important;
						width: 2px !important;
						right: 29px !important;

					}
				}
			}



			.btn[aria-expanded="true"] {

				&:after {
					display: none !important;
				}
			}

		}

		.card-body {
			padding: 10px 0 0;
		}
	}
}

.hundeurlaub-wraper-v1,
.entdecken-v1 {
	img {
		width: 100%;
	}
}

.entdecken-col {
	h3 {
		display: flex;
		justify-content: space-between;
		padding: 10px 15px;
		background: var(--color-primary);
		color: var(--color-white);
	}

}

.cta-wraper-v1 {
	background: var(--color-grey-light2);
	padding: 50px 0;
}

.checkmark-list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 200px;
	align-content: space-between;
	width: 60%;

	@media (max-width:1199px) {
		width: 80%;
	}

	@media (max-width:992px) {
		width: 100%;
	}

	@media (max-width:767px) {
		height: auto;
		display: block;
	}

	li {
		margin-bottom: 20px;
		position: relative;

		&:before {
			content: "\f00c";
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			margin-right: 10px;
			color: #007bb1;
		}
	}
}

.vermietung-v1 {
	.video-block {
		@media (max-width:767px) {
			padding-top: 0;
			height: auto;
		}

		video {
			width: 100%;
		}
	}
}