.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.checkbox label:before {
		top: 4px;
	}

	.checkbox label:after {
		top: 4px;
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.dlg {
		.body {
			padding: 10px !important;
		}
	}

	.video-container {
		position: relative;
		/*padding-bottom: 56.25%;*/
		/* 16:9 Format */
		width: 100%;
		aspect-ratio: 16/9;

		@media(max-width: 470px) {
			height: 235px;
		}

		iframe {
			width: 100%;
			height: 100%;

			@media (max-width:500px) {
				height: 400px;
			}

		}
	}



	.text-end {
		text-align: right !important;
	}

	.video-hint {
		position: relative;
		background-color: #000;
		aspect-ratio: 16/9;
		padding: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;

		@media(max-width:500px) {
			padding: 5px;
			font-size: 13px;


		}

		.btn {
			@media(max-width:500px) {
				font-size: 11px;
			}
		}

		.text {
			text-align: center;

			@media(max-width:500px) {
				margin-bottom: 10px;
			}
		}

		.button-line {
			text-align: center;
			margin: 10px 0px;

			@media(max-width:500px) {
				margin: 0px 0px;
			}
		}
	}

	.unit-contact-form-v1 {}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}