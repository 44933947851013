.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			&.crown {
				display: flex;
				align-items: center;

				.fas {
					width: 31px;

					&.fa-crown:before {
						content: "\f521";
						width: 22px;
						height: 16px;
						text-align: center;
						border: 1px solid rgb(51 51 51/80%);
						border-radius: 3px;
						font-size: 8px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}



			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);

		.fa-star {
			background-image: url(RESOURCE/img/fullstar-1.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}

		.fa-star-o {
			background-image: url(RESOURCE/img/borderstar-4.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}
	}

}