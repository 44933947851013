.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.search-offer-info-v1 {
		position: relative;
		margin-top: 10px;
		color: #516970;
		color: var(--color-primary);
		text-align: center;
		padding: 10px;
		border: 3px dashed #b7bb3e;
		border: 3px dashed var(--color-secondary-light);
		font-weight: 700;
		margin-bottom: 30px;
	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

	.traumhafte-v1 {
		.col-lg-4 {
			position: relative;
			padding-bottom: 50px;

			.search-item {
				a {
					position: absolute;
					bottom: 0;
					font-size: 14px;
					left: 15px;
					width: calc(100% - 15px);

					@media (max-width:991px) {
						position: relative;
						width: 100%;
						left: auto;
					}

					.icon-arrow {
						@media (max-width:1199px) {
							display: none;
						}

						@media (max-width:991px) {
							display: block;
						}

					}
				}
			}
		}
	}
}

.img-block {
	position: relative;
	background-size: cover;
	display: block;

	&.region-8784 {
		background-image: url(RESOURCE/img/fussen.jpg);
	}

	&.region-8797 {
		background-image: url(RESOURCE/img/fussen.jpg);
	}

	&.region-8799 {
		background-image: url(RESOURCE/img/fussen.jpg);
	}

	&.region-8798 {
		background-image: url(RESOURCE/img/fussen.jpg);
	}

	&.region-8794 {
		background-image: url(RESOURCE/img/hopfen.jpg);
	}

	&.region-8800 {
		background-image: url(RESOURCE/img/hopferau.jpg);
	}

	&.region-8766 {
		background-image: url(RESOURCE/img/hopferau.jpg);
	}

	&.region-8792 {
		background-image: url(RESOURCE/img/pfronten.jpg);
	}

	&.region-8795 {
		background-image: url(RESOURCE/img/rieden.jpg);
	}

	&.region-8796 {
		background-image: url(RESOURCE/img/pfronten.jpg);
	}

	&.region-8793 {
		background-image: url(RESOURCE/img/schwangau.jpg);
	}

	&.region-9213 {
		background-image: url(RESOURCE/img/schwangau.jpg);
	}

	.img-link {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;

	}

	.img-over-title {
		background-color: rgba(139, 132, 117, .7);
		padding: 5px 10px;
		position: absolute;
		color: var(--color-white);
		font-size: 20px;
		border-radius: 5px;
		display: block;
		bottom: 10px;
		left: 15px;
		right: 15px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.max-250 {
		height: 250px;
	}

	&.max-240 {
		height: 240px;
	}

	&.max-260 {
		height: 260px;
	}
}

.btn-arrow-white {
	position: relative;
	text-align: left;

	.icon-arrow {
		background-image: url(RESOURCE/img/icon-white-arrow.svg);
		width: 25px;
		height: 22px;
		position: absolute;
		right: 11px;
		top: 10px;
		display: inline-block;
		background-size: contain;
	}
}


.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}