.about-us-v1 {
	margin-top: var(--page-margin-top);


	.inner-banner {
		&.about-team {
			background-image: url(RESOURCE/img/Teamfotov2.jpg);

		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;

		.member {
			background: var(--color-white);
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);
		padding: 30px 1em !important;
		justify-content: flex-start !important;

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.team-bl {
		img {
			width: 100%;
			height: auto;
		}
	}

	.blogcontent {
		img {
			max-height: 360px !important;
			width: 100%;
			height: 100%;
			object-fit: cover;
			padding: 0 !important;
			border: none !important;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}

		p a {
			background-color: var(--btn-secondary-bg-color);
			font-size: var(--font-size-sm);
			padding: 6px 12px;
			color: var(--btn-secondary-font-color);
			border-radius: var(--btn-border-radius);
			border-color: var(--btn-secondary-border-color);
			display: inline-block;
			text-decoration: none;

			&:hover {
				background-color: var(--btn-secondary-bg-color-hover);
				border-color: var(--btn-secondary-border-color-hover);
				color: var(--btn-secondary-font-color-hover);
			}
		}
	}


}