.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.stars {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: var(--color-stars);
		background-color: white;
		padding: 9px;
		border-radius: 5px;
		display: flex;
		align-items: center;

		.fa-star {
			background-image: url(RESOURCE/img/fullstar-1.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}

		.far.fa-star,
		.fa-star-o {
			background-image: url(RESOURCE/img/borderstar-4.svg);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 22px;

			&:before {
				display: none;
			}
		}
	}

	.facility-unit-wrap {
		.searchbar-v1 {
			position: unset;

			.form-flex {
				text-align: left;
			}
		}
	}
}