@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}


html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	display: inline-block;

	border-bottom: 1px solid var(--heading1-border-color);
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	padding-bottom: 10px;
	margin-bottom: 40px;
}

.banner-img-region {
	margin-top: 75px;
	margin-bottom: 45px;

	&.hide {
		display: none;
	}

	@media (min-width:992px) {
		height: calc(100vh - 195px);
		min-height: 480px;
		position: relative;
	}

	@media (max-width:991px) {
		height: auto;
		min-height: auto;
	}

	@media (max-width:767px) {
		min-height: auto;
		position: relative;
		/*padding-top: 50%;*/
		height: auto;
	}



	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);
	pointer-events: inherit !important;

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}


/* home video css */
.home-banner-video {
	margin-top: 75px;
	margin-bottom: 45px;

	.video-block {

		overflow: hidden;

		@media (min-width:992px) {
			height: calc(100vh - 195px);
			min-height: 480px;
			position: relative;
		}

		@media (max-width:991px) {
			height: auto;
			min-height: 300px;
		}

		@media (max-width:767px) {
			min-height: auto;
			position: relative;
			padding-top: 50%;
			height: 490px;
		}

		@media (max-width:550px) {
			height: 690px;
		}

		@media (max-width:390px) {
			height: 490px;
		}

		.mute-icon {
			position: absolute;
			top: 60%;
			left: 10px;

			@media (max-width: 993px) {
				top: 220px;
			}

			@media (max-width: 767px) {
				top: 125px;
			}

			a {
				background: #00000040;
				width: 62px;
				height: 62px;
				text-align: center;
				line-height: 72px;
				display: block;

				/*&:first-child {
					display: none;
				}*/

				&.mute {
					.fas {
						&:before {
							content: "\f6a9";
							font-size: xx-large;
						}
					}
				}

				.fas {
					&:before {
						content: "\f028";
						font-size: xx-large;
					}
				}
			}
		}
	}

	.video {
		max-width: 100%;
		width: 100%;
		position: absolute;
		/*	transform: translateY(-50%);
				top: 50%;*/
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center;

		/*@media (max-width: 1400px) {
					top: 50%;
				}*/

		@media (max-width: 1200px) {
			/*	transform: translateY(-50%);
              left: 50%;
					top: 50%;*/
			height: 100%;

			width: auto;
			max-width: inherit;
		}

		@media (max-width: 991px) {
			transform: translate(-50%, 0);
			top: 0;
			height: auto;
			left: 50%;
			width: 100%;
			max-width: inherit;
		}

		@media (max-width: 550px) {
			height: 100%;
			width: auto;
			max-width: inherit;
			left: 50%;
			transform: translate(-50%, 0);
			top: 0;
			/*position: relative;*/
			position: absolute;
		}


	}
}

.index-banner-video {
	.video-block {

		overflow: hidden;

		@media (min-width:992px) {
			/*	height: calc(100vh - 195px);*/
			min-height: 480px;
			position: relative;
		}

		@media (max-width:991px) {
			height: auto;
			min-height: 300px;
		}

		@media (max-width:767px) {
			min-height: auto;
			position: relative;
			padding-top: 50%;
			height: 490px;
		}

		@media (max-width:550px) {
			height: 690px;
		}

		@media (max-width:390px) {
			height: 490px;
		}

		.mute-icon {
			position: absolute;
			top: 60%;
			left: 10px;
			z-index: 9;

			@media (max-width: 993px) {
				top: 220px;
			}

			@media (max-width: 767px) {
				top: 125px;
			}

			a {
				background: #00000040;
				width: 62px;
				height: 62px;
				text-align: center;
				line-height: 72px;
				display: block;

				/*&:first-child {
					display: none;
				}*/

				&.mute {
					.fas {
						&:before {
							content: "\f6a9";
							font-size: xx-large;
						}
					}
				}

				.fas {
					&:before {
						content: "\f028";
						font-size: xx-large;
					}
				}
			}
		}
	}
}

#accordion {
	.accord-body {
		padding: 2px 10px;
	}

	.button {
		&:after {
			background-image: none !important;
		}

		&:hover {
			text-decoration: none;
		}
	}

	button,
	.button {
		border: none;
		background-color: var(--color-grey-light2);
		padding-right: 30px;
		position: relative;
		width: 100%;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		text-align: left;
		padding: 8px 10px;
		display: block;
		color: var(--h5-font-color);


		&:after {
			flex-shrink: 0;
			width: 1.25rem;
			height: 1.25rem;
			margin-left: auto;
			content: "";
			background-image: url(RESOURCE/img/icon-minus.svg);
			background-repeat: no-repeat;
			background-size: 1.25rem;
			display: block;
			position: absolute;
			right: 20px;
			top: 11px;
			background-position: center;

		}

		&.collapsed {
			&:after {
				background-image: url(RESOURCE/img/icon-plus.svg);
			}
		}
	}
}

.icon-white-arrow {
	background-image: url(RESOURCE/img/icon-white-arrow.svg);
	width: 26px;
	height: 22px;
	display: inline-block;
}

.tick-li {
	li {
		margin: 0 0 30px;
		position: relative;
		padding-left: 35px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.icon-tick {
		background-image: url(RESOURCE/img/icon-tick.svg);
		width: 26px;
		position: absolute;
		background-repeat: no-repeat;
		background-position: center;
		height: 18px;
		display: inline-block;
		left: 0;
		top: 3px;
		background-size: contain;
	}
}



.divier {
	margin: 50px 0 0;
	text-align: center;

	&:after {
		content: "";
		width: 233px;
		height: 1px;
		background-color: var(--color-secondary);
		display: inline-block;
		margin: 0 auto;
	}
}


ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.inner-pages {
	.navbar-v1 .header-main .icon {
		color: var(--color-secondary) !important;
	}
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.bg-grey-light2 {
	background-color: var(--color-grey-light2);
}

.f24 {
	font-size: 24px;
}

.img-responsive {
	max-width: 100%;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-secondary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner-1.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 420px;
	position: relative;

	@media (max-width:992px) {
		height: 220px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;
		display: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-white);

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}

	.container-xl {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	top: 120px;
	right: 40px;
	z-index: 2;
	transform: rotate(10deg);


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 150px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 150px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 18px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}
	}


}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.region-bl {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	li {
		padding: 0 5px;
		margin-bottom: 10px;
		width: 33.33%;
		position: relative;

		.region-img {
			padding-bottom: 70%;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
			}
		}

		/*.region-text {
			position: relative;
		}*/

		h3 {
			/*position: absolute;
			bottom: 0;
			width: 100%;
			background-color: var(--overlay-color);
			padding: 10px;
			text-align: center;*/
			margin: 0 0 15px;
			color: var(--color-white);
			font-size: var(--h5-font-size);
			font-weight: 800;

			a {
				font-size: 16px;
				color: var(--color-white);
			}
		}

		.region-text-container {
			overflow: hidden;
			position: relative;

			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}

			.region-text {
				position: absolute;
				width: 100%;
				background-color: var(--overlay-color);
				padding: 10px;
				text-align: center;
				bottom: 0;
				height: 45px;
				transition: 1s;

				@media (max-width: 767px) {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 100%;
				}

				p {
					opacity: 0;
					transition: ease-out 0.5s;
					color: var(--color-white);
					font-size: 14px;
					display: none;

					@media (max-width: 767px) {
						opacity: 1;
					}

					&:nth-child(1) {
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						line-clamp: 4;
						-webkit-box-orient: vertical;
					}
				}
			}

			&:hover {
				.region-text {
					height: 100%;
				}

				p {
					opacity: 1;

				}
			}
		}

		@media (max-width: 992px) {
			width: 33.33%;
		}

		@media (max-width: 767px) {
			width: 50%;
		}

		@media (max-width: 479px) {
			width: 100%;
		}


	}
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Medium.eot');
	src: url('RESOURCE/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Regular.eot');
	src: url('RESOURCE/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Tangerine';
	src: url('RESOURCE/fonts/Tangerine.eot');
	src: url('RESOURCE/fonts/Tangerine.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Tangerine.woff2') format('woff2'),
		url('RESOURCE/fonts/Tangerine.woff') format('woff'),
		url('RESOURCE/fonts/Tangerine.ttf') format('truetype'),
		url('RESOURCE/fonts/Tangerine.svg#Tangerine') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.static-searchbar-v1 {
	.checkbox {
		margin-top: 20px;

		@media (max-width:992px) {
			padding: 0 15px;
		}

		label {
			margin: 0;



			&:before {
				content: "";
				top: 4px;
			}

			&:after {
				content: "";
				top: 3px;
			}
		}

	}
}

#goTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	height: 40px;
	width: 40px;
	background: #007bb1;
	border-radius: 50%;
	z-index: 10;
	display: none;

	&.scrolltop {
		@media(max-width: 1199px) {
			display: block;
		}
	}

	@media(min-width: 1199px) {
		z-index: -1;
		position: fixed;
		bottom: 0;
		right: 0;
		height: 70px;
		width: 100%;
		background: #333;
		border-radius: 0;
	}

	span {
		@media(min-width: 1199px) {
			width: 26px;
			height: 26px;
			margin: 30px auto 0 auto;
			display: block;
			position: relative;
			transform: rotate(-180deg);
		}

		&:before {
			height: 11px;
			left: 15px;
			top: 14px;
			width: 3px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&:after {
			height: 11px;
			right: 15px;
			top: 14px;
			width: 3px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(-45deg);
		}

		&:after,
		&:before {
			background-color: #ffffff;
			content: "";
			position: absolute;
			z-index: 1;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
	}
}

.video-col {
	video {
		width: 100%;
	}
}